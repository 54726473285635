<template>
  <div>
    <h1>消息推送</h1>
    <el-form
      label-width="80px"
      :model="postForm"
      label-position="left"
      :rules="postFormRules"
      ref="postFormRef"
    >
      <el-form-item label="组织名称" prop="user_serial">
        <el-input
          v-model="postForm.user_serial"
          placeholder="该项不可修改"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="群发">
        <el-switch v-model="postForm.send_to_all"></el-switch>
      </el-form-item>
      <div style="font-size:14px;color:#5c5c5c;margin-bottom:20px">
        <div>未开启群发时，下方所填写的用户邮箱为接收本次通知推送的用户</div>
        <div>开启群发后，该条通知将群发给所有关注HKU ONE的用户，该通知需要接受HKU ONE审核，下方所填写的用户邮箱为接收预览和发送结果通知的用户</div>
    </div>
      <el-form-item label="用户邮箱" prop="user_itsc">
        <el-input
          v-model="postForm.user_itsc"
          placeholder="只写@前的部分，如邮箱为test@connect.hku.hk，只写test"
        ></el-input>
      </el-form-item>
      <el-form-item label="通知标题" prop="notice_title">
        <el-input
          v-model="postForm.notice_title"
          placeholder="包含社团名称和通知主要内容，如「HKUPootal OCamp报名通知」"
        ></el-input>
      </el-form-item>
      <el-form-item label="通知内容" prop="notice_content">
        <el-input
          type="textarea"
          v-model="postForm.notice_content"
          placeholder="通知主体内容，建议不要超过50字，否则可能被隐藏"
        ></el-input>
      </el-form-item>
      <el-form-item label="通知备注" prop="notice_remark">
        <el-input
          type="textarea"
          v-model="postForm.notice_remark"
          placeholder="通知备注，以灰色显示，如「点击即可跳转报名网站」"
        ></el-input>
      </el-form-item>
      <el-form-item label="链接类型" prop="notice_type">
        <el-select
          v-model="postForm.notice_type"
          placeholder="选择链接类型"
          @change="redraw"
        >
          <el-option label="无" value="none"></el-option>
          <el-option label="网页" value="url"></el-option>
          <el-option label="小程序" value="miniapp"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="网页链接" prop="url_link" v-if="postForm.notice_type == 'url'">
        <el-input
          v-model="postForm.url_link"
          placeholder="要跳转的网页链接，可以是微信公众号链接，也可以是任何网站"
        ></el-input>
      </el-form-item>
      <el-form-item label="APPID" prop="miniapp_appid" v-if="postForm.notice_type == 'miniapp'">
        <el-input
          v-model="postForm.miniapp_appid"
          placeholder="小程序的APPID，需绑定公众号「HKU ONE」，如未绑定请联系管理员"
        ></el-input>
      </el-form-item>
      <el-form-item label="页面路径" prop="miniapp_path" v-if="postForm.notice_type == 'miniapp'">
        <el-input
          v-model="postForm.miniapp_path"
          placeholder="小程序页面路径"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-button type="primary" style="margin-top: 10px" @click="singlePush" v-if="!postForm.send_to_all"
      >发送</el-button
    >
    <el-button type="primary" style="margin-top: 10px" @click="singlePush" v-if="postForm.send_to_all"
      >发送预览</el-button
    >
    <el-button type="primary" style="margin-top: 10px" @click="allPush" v-if="postForm.send_to_all"
      >群发</el-button
    >
  </div>
</template>

<script>
export default {
  created() {
    this.getUserInfo();
  },
  data() {
    return {
      postForm: {
        user_serial: "",
        user_itsc: "",
        notice_title: "",
        notice_content: "",
        notice_remark: "",
        notice_type:"",
        url_link: "",
        miniapp_appid: "",
        miniapp_path: "",
        send_to_all:false
      },
      postFormRules: {
        user_serial: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        user_itsc: [
          { required: true, message: "请输入用户邮箱", trigger: "blur" },
        ],
        notice_title: [
          { required: true, message: "请输入通知标题", trigger: "blur" },
        ],
        notice_content: [
          { required: true, message: "请输入通知内容", trigger: "blur" },
        ],
        notice_remark: [
          { required: true, message: "请输入通知备注", trigger: "blur" },
        ],
        notice_type: [
          { required: true, message: "请选择链接类型", trigger: "blur" },
        ],

      },
    };
  },
  methods: {
    async getUserInfo() {
      const token = window.sessionStorage.getItem("token");
      if (!token) {
        this.$message.error("请先登录");
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const { data: res } = await this.$http.post(
        "user/info.php",
        "token=" + token
      );
      console.log(res);
      this.loading = false;
      if (res.code == 200) {
        this.postForm.user_serial = res.user_serial;
      } else {
        window.sessionStorage.removeItem("token");
        this.$message.error("请先登录");
        this.$router.push("/login");
      }
    },
    singlePush() {
      this.$refs.postFormRef.validate(async (valid) => {
        if (!valid) return;
        const token = window.sessionStorage.getItem("token");
        if (!token) {
          this.$message.error("请先登录");
          this.$router.push("/login");
          return;
        }

        const { data: res } = await this.$http.post(
          "push/single.php",
          "token=" +
            token +
            "&user_itsc=" +
            this.postForm.user_itsc +
            "&notice_title=" +
            this.postForm.notice_title +
            "&notice_content=" +
            this.postForm.notice_content +
            "&notice_remark=" +
            this.postForm.notice_remark +
            "&notice_type=" +
            this.postForm.notice_type +
            "&url_link=" +
            this.postForm.url_link +
            "&miniapp_appid=" +
            this.postForm.miniapp_appid +
            "&miniapp_path=" +
            this.postForm.miniapp_path
        );
        console.log(res);
        if (res.code == 200) {
          this.$message.success("推送成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    allPush() {
      this.$refs.postFormRef.validate(async (valid) => {
        if (!valid) return;
        const token = window.sessionStorage.getItem("token");
        if (!token) {
          this.$message.error("请先登录");
          this.$router.push("/login");
          return;
        }

        const { data: res } = await this.$http.post(
          "push/audit.php",
          "token=" +
            token +
            "&user_itsc=" +
            this.postForm.user_itsc +
            "&notice_title=" +
            this.postForm.notice_title +
            "&notice_content=" +
            this.postForm.notice_content +
            "&notice_remark=" +
            this.postForm.notice_remark +
            "&notice_type=" +
            this.postForm.notice_type +
            "&url_link=" +
            encodeURIComponent(this.postForm.url_link) +
            "&miniapp_appid=" +
            this.postForm.miniapp_appid +
            "&miniapp_path=" +
            encodeURIComponent(this.postForm.miniapp_path)
        );
        console.log(res);
        if (res.code == 200) {
          this.$message.success("提交成功，等待审核中");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less">
.el-table .cell {
  padding-right: 0;
}
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>