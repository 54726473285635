<template>
  <div>
    <h1>主页定制</h1>
    <el-form
      label-width="80px"
      :model="postForm"
      label-position="left"
      :rules="postFormRules"
      ref="postFormRef"
    >
      <el-form-item label="组织名称" prop="org_name">
        <el-input
          v-model="postForm.org_name"
          placeholder="该项不可修改"
          :disabled="true"
          @input="redraw"
        ></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="org_intro">
        <el-input
          type="textarea"
          v-model="postForm.org_intro"
          @input="redraw"
        ></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="org_avatar">
        <el-image
          style="height: 100px"
          :src="postForm.org_avatar"
          :preview-src-list="[postForm.org_avatar]"
        >
        </el-image>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_avatar_upload"
          v-loading="org_avatar_loading"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为1:1</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="背景图" prop="org_bg_image">
        <el-image
          style="height: 100px"
          :src="postForm.org_bg_image"
          :preview-src-list="[postForm.org_bg_image]"
        >
        </el-image>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_bg_image_upload"
          v-loading="org_bg_image_loading"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为3:4</div>
        </el-upload>
      </el-form-item>
      <el-button type="primary" @click="addBanner">新增轮播图</el-button>
      <el-form-item
        label="轮播图"
        prop="org_banner"
        v-for="(item, index) in postForm.org_banner"
        :key="item"
      >
      
        <el-image
          style="height: 100px"
          :src="item.banner_image"
          :preview-src-list="[item.banner_image]"
        >
        </el-image>
        <el-button type="danger" @click="deleteBanner(index)"
              >删除</el-button
            >
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_banner_upload0"
          v-loading="org_banner_loading0"
          v-if="index == 0"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为2.35:1</div>
        </el-upload>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_banner_upload1"
          v-loading="org_banner_loading1"
          v-if="index == 1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为2.35:1</div>
        </el-upload>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_banner_upload2"
          v-loading="org_banner_loading2"
          v-if="index == 2"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为2.35:1</div>
        </el-upload>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_banner_upload3"
          v-loading="org_banner_loading3"
          v-if="index == 3"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为2.35:1</div>
        </el-upload>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          drag
          :before-upload="org_banner_upload4"
          v-loading="org_banner_loading4"
          v-if="index == 4"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为2.35:1</div>
        </el-upload>
        <el-form-item label="文字">
          <el-input
            v-model="item.banner_title"
            placeholder="以黑色遮照显示在轮播图下方，留空即为不显示"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接类型">
          <el-select
            v-model="item.banner_type"
            placeholder="点击触发的链接"
            @change="redraw"
          >
            <el-option label="公众号文章" value="article"></el-option>
            <el-option label="噗噗树洞" value="post"></el-option>
            <el-option label="噗噗页面" value="inner"></el-option>
            <el-option label="其他小程序" value="miniapp"></el-option>
            <el-option label="无" value="none"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="文章链接"
          v-if="item.banner_type == 'article'"
        >
          <el-input
            v-model="item.article_link"
            placeholder="https://mp.weixin.qq.com开头的链接，公众号须已关联「薄扶林噗噗」小程序"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="树洞号"
          v-if="item.banner_type == 'post'"
        >
          <el-input
            v-model="item.post_id"
            placeholder="需要跳转的树洞号"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="页面路径"
          v-if="item.banner_type == 'inner'"
        >
          <el-input
            v-model="item.inner_path"
            placeholder="不知道的话请咨询Vector"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="APPID"
          v-if="item.banner_type == 'miniapp'"
        >
          <el-input
            v-model="item.miniapp_appid"
            placeholder="联系该小程序管理员获取"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="页面路径"
          v-if="item.banner_type == 'miniapp'"
        >
          <el-input
            v-model="item.miniapp_path"
            placeholder="联系该小程序管理员获取"
            @input="redraw"
          ></el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item label="功能(大)" prop="org_model_main">
        <el-form-item label="功能名称">
          <el-input
            v-model="postForm.org_model_main.function_title"
            placeholder="功能名称，显示在小程序社团主页，留空即为不显示"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="功能类型">
          <el-select
            v-model="postForm.org_model_main.function_type"
            placeholder="请选择功能类型"
            @change="redraw"
          >
            <el-option label="公众号文章" value="article"></el-option>
            <el-option label="噗噗树洞" value="post"></el-option>
            <el-option label="私信" value="pm"></el-option>
            <el-option label="噗噗页面" value="inner"></el-option>
            <el-option label="其他小程序" value="miniapp"></el-option>
            <el-option label="二级菜单" value="list"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="文章链接"
          v-if="postForm.org_model_main.function_type == 'article'"
        >
          <el-input
            v-model="postForm.org_model_main.article_link"
            placeholder="https://mp.weixin.qq.com开头的链接，公众号须已关联「薄扶林噗噗」小程序"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="树洞号"
          v-if="postForm.org_model_main.function_type == 'post'"
        >
          <el-input
            v-model="postForm.org_model_main.post_id"
            placeholder="需要跳转的树洞号"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="页面路径"
          v-if="postForm.org_model_main.function_type == 'inner'"
        >
          <el-input
            v-model="postForm.org_model_main.inner_path"
            placeholder="不知道的话请咨询Vector"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="APPID"
          v-if="postForm.org_model_main.function_type == 'miniapp'"
        >
          <el-input
            v-model="postForm.org_model_main.miniapp_appid"
            placeholder="联系该小程序管理员获取"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="页面路径"
          v-if="postForm.org_model_main.function_type == 'miniapp'"
        >
          <el-input
            v-model="postForm.org_model_main.miniapp_path"
            placeholder="联系该小程序管理员获取"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="二级菜单"
          v-if="postForm.org_model_main.function_type == 'list'"
        >
          <el-button type="primary" @click="addSecondInMain"
            >新增二级选项</el-button
          >
          <el-form-item
            label="二级选项"
            prop="org_model_half"
            v-for="(item, index) in postForm.org_model_main.function_list"
            :key="index"
          >
            <el-button type="danger" @click="deleteSecondInMain(index)"
              >删除</el-button
            >
            <el-form-item label="功能名称">
              <el-input
                v-model="item.function_title"
                placeholder="功能名称，显示在二级菜单"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="功能类型">
              <el-select
                v-model="item.function_type"
                placeholder="请选择功能类型"
                @change="redraw"
              >
                <el-option label="公众号文章" value="article"></el-option>
                <el-option label="噗噗树洞" value="post"></el-option>
                <el-option label="私信" value="pm"></el-option>
                <el-option label="噗噗页面" value="inner"></el-option>
                <el-option label="其他小程序" value="miniapp"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="文章链接"
              v-if="item.function_type == 'article'"
            >
              <el-input
                v-model="item.article_link"
                placeholder="https://mp.weixin.qq.com开头的链接，公众号须已关联「薄扶林噗噗」小程序"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="树洞号" v-if="item.function_type == 'post'">
              <el-input
                v-model="item.post_id"
                placeholder="需要跳转的树洞号"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="页面路径" v-if="item.function_type == 'inner'">
              <el-input
                v-model="item.inner_path"
                placeholder="不知道的话请咨询Vector"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="APPID" v-if="item.function_type == 'miniapp'">
              <el-input
                v-model="item.miniapp_appid"
                placeholder="联系该小程序管理员获取"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="页面路径"
              v-if="item.function_type == 'miniapp'"
            >
              <el-input
                v-model="item.miniapp_path"
                placeholder="联系该小程序管理员获取"
                @input="redraw"
              ></el-input>
            </el-form-item>
          </el-form-item>
        </el-form-item>
      </el-form-item>
      <el-button type="primary" @click="addInHalf">新增功能(小)</el-button>
      <el-form-item
        label="功能(小)"
        prop="org_model_half"
        v-for="(item, index) in postForm.org_model_half"
        :key="index"
      >
        <el-button type="danger" @click="deleteInHalf(index)">删除</el-button>
        <el-form-item label="功能名称">
          <el-input
            v-model="item.function_title"
            placeholder="功能名称，显示在小程序社团主页"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="功能类型">
          <el-select
            v-model="item.function_type"
            placeholder="请选择功能类型"
            @change="redraw"
          >
            <el-option label="公众号文章" value="article"></el-option>
            <el-option label="噗噗树洞" value="post"></el-option>
            <el-option label="私信" value="pm"></el-option>
            <el-option label="噗噗页面" value="inner"></el-option>
            <el-option label="其他小程序" value="miniapp"></el-option>
            <el-option label="二级菜单" value="list"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章链接" v-if="item.function_type == 'article'">
          <el-input
            v-model="item.article_link"
            placeholder="https://mp.weixin.qq.com开头的链接，公众号须已关联「薄扶林噗噗」小程序"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="树洞号" v-if="item.function_type == 'post'">
          <el-input
            v-model="item.post_id"
            placeholder="需要跳转的树洞号"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="页面路径" v-if="item.function_type == 'inner'">
          <el-input
            v-model="item.inner_path"
            placeholder="不知道的话请咨询Vector"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="APPID" v-if="item.function_type == 'miniapp'">
          <el-input
            v-model="item.miniapp_appid"
            placeholder="联系该小程序管理员获取"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="页面路径" v-if="item.function_type == 'miniapp'">
          <el-input
            v-model="item.miniapp_path"
            placeholder="联系该小程序管理员获取"
            @input="redraw"
          ></el-input>
        </el-form-item>
        <el-form-item label="二级菜单" v-if="item.function_type == 'list'">
          <el-button type="primary" @click="addSecondInHalf(index)"
            >新增二级选项</el-button
          >
          <el-form-item
            label="二级选项"
            prop="org_model_half"
            v-for="(item2, index2) in item.function_list"
            :key="index2"
          >
            <el-button type="danger" @click="deleteSecondInHalf(index, index2)"
              >删除</el-button
            >
            <el-form-item label="功能名称">
              <el-input
                v-model="item2.function_title"
                placeholder="功能名称，显示在二级菜单"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="功能类型">
              <el-select
                v-model="item2.function_type"
                placeholder="请选择功能类型"
                @change="redraw"
              >
                <el-option label="公众号文章" value="article"></el-option>
                <el-option label="噗噗树洞" value="post"></el-option>
                <el-option label="私信" value="pm"></el-option>
                <el-option label="噗噗页面" value="inner"></el-option>
                <el-option label="其他小程序" value="miniapp"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="文章链接"
              v-if="item2.function_type == 'article'"
            >
              <el-input
                v-model="item2.article_link"
                placeholder="https://mp.weixin.qq.com开头的链接，公众号须已关联「薄扶林噗噗」小程序"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="树洞号" v-if="item2.function_type == 'post'">
              <el-input
                v-model="item2.post_id"
                placeholder="需要跳转的树洞号"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="页面路径"
              v-if="item2.function_type == 'inner'"
            >
              <el-input
                v-model="item2.inner_path"
                placeholder="不知道的话请咨询Vector"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item label="APPID" v-if="item2.function_type == 'miniapp'">
              <el-input
                v-model="item2.miniapp_appid"
                placeholder="联系该小程序管理员获取"
                @input="redraw"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="页面路径"
              v-if="item2.function_type == 'miniapp'"
            >
              <el-input
                v-model="item2.miniapp_path"
                placeholder="联系该小程序管理员获取"
                @input="redraw"
              ></el-input>
            </el-form-item>
          </el-form-item>
        </el-form-item>
      </el-form-item>
    </el-form>
    <el-button type="primary" style="margin-top: 10px" @click="post"
      >更新</el-button
    >
  </div>
</template>

<script>
export default {
  created() {
    this.getPageInfo();
  },
  data() {
    return {
      postForm: {
        org_name: "",
        org_intro: "",
        org_avatar: "",
        org_bg_image: "",
        org_banner: [],
        org_model_main: {},
        org_model_half: [],
      },
      postFormRules: {
        user_serial: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        article_link: [
          { required: true, message: "请输入文章链接", trigger: "blur" },
        ],
        article_title: [
          { required: true, message: "请输入文章标题", trigger: "blur" },
        ],
        article_image: [
          { required: true, message: "请上传封面图片", trigger: "blur" },
        ],
      },
      org_avatar_loading: false,
      org_bg_image_loading: false,
      org_banner_loading0: false,
      org_banner_loading1: false,
      org_banner_loading2: false,
      org_banner_loading3: false,
      org_banner_loading4: false,
    };
  },
  methods: {
    async getPageInfo() {
      const token = window.sessionStorage.getItem("token");
      if (!token) {
        this.$message.error("请先登录");
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const { data: res } = await this.$http.post(
        "page/get.php",
        "token=" + token
      );
      console.log(res);
      this.loading = false;
      if (res.code == 200) {
        this.postForm.org_name = res.orgInfo.org_name;
        this.postForm.org_intro = res.orgInfo.org_intro;
        this.postForm.org_avatar = res.orgInfo.org_avatar;
        this.postForm.org_bg_image = res.orgInfo.org_bg_image;
        this.postForm.org_banner = res.orgInfo.org_banner;
        this.postForm.org_model_main = res.orgInfo.org_model_main;
        this.postForm.org_model_half = res.orgInfo.org_model_half;
      } else {
        window.sessionStorage.removeItem("token");
        this.$message.error("请先登录");
        this.$router.push("/login");
      }
    },
    async post() {
      const token = window.sessionStorage.getItem("token");
      if (!token) {
        this.$message.error("请先登录");
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const { data: res } = await this.$http.post(
        "page/post.php",
        "token=" +
          token +
          "&org_intro=" +
          this.postForm.org_intro +
          "&org_avatar=" +
          this.postForm.org_avatar +
          "&org_bg_image=" +
          this.postForm.org_bg_image +
          "&org_banner=" +
          encodeURIComponent(JSON.stringify(this.postForm.org_banner)) +
          "&org_model_main=" +
          encodeURIComponent(JSON.stringify(this.postForm.org_model_main)) +
          "&org_model_half=" +
          encodeURIComponent(JSON.stringify(this.postForm.org_model_half))
      );
      console.log(res);
      if (res.code == 200) {
        this.$message.success("更新成功");
      } else if (res.code == 5) {
        this.$message.error(res.msg);
      } else {
        // window.sessionStorage.removeItem("token");
        // this.$message.error("请先登录");
        // this.$router.push("/login");
      }
    },
    // post() {
    //   console.log(this.postForm);
    // },
    addBanner() {
      var that = this;
      if (!that.postForm.org_banner) {
        that.postForm.org_banner = [];
      }
      if (that.postForm.org_banner.length < 5) {
        that.postForm.org_banner.push({
          banner_title: "",
          banner_type: "",
          banner_image:"",
        });
      } else {
        that.$message.error("轮播图最多5个");
      }
      that.$forceUpdate();
    },
    addInHalf() {
      var that = this;
      if (!that.postForm.org_model_half) {
        that.postForm.org_model_half = [];
      }
      if (that.postForm.org_model_half.length < 4) {
        that.postForm.org_model_half.push({
          function_title: "",
          function_type: "",
        });
      } else {
        that.$message.error("功能(小)最多4个");
      }
      that.$forceUpdate();
    },
    addSecondInMain() {
      var that = this;
      if (!that.postForm.org_model_main.function_list) {
        that.postForm.org_model_main.function_list = [];
      }
      if (that.postForm.org_model_main.function_list.length < 6) {
        that.postForm.org_model_main.function_list.push({
          function_title: "",
          function_type: "",
        });
      } else {
        that.$message.error("二级选项最多6个");
      }
      that.$forceUpdate();
    },
    addSecondInHalf(index) {
      var that = this;
      if (!that.postForm.org_model_half[index].function_list) {
        that.postForm.org_model_half[index].function_list = [];
      }
      if (that.postForm.org_model_half[index].function_list.length < 6) {
        that.postForm.org_model_half[index].function_list.push({
          function_title: "",
          function_type: "",
        });
      } else {
        that.$message.error("二级选项最多6个");
      }
      that.$forceUpdate();
    },
    deleteBanner(index) {
      this.postForm.org_banner.splice(index, 1);
      this.$forceUpdate();
    },
    deleteInHalf(index) {
      this.postForm.org_model_half.splice(index, 1);
      this.$forceUpdate();
    },
    deleteSecondInMain(index) {
      this.postForm.org_model_main.function_list.splice(index, 1);
      this.$forceUpdate();
    },
    deleteSecondInHalf(index, index2) {
      this.postForm.org_model_half[index].function_list.splice(index2, 1);
      this.$forceUpdate();
    },
    org_avatar_upload(file) {
      console.log(file);
      var that = this;
      that.org_avatar_loading = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_avatar = location;
            that.org_avatar_loading = false;
          }
        }
      );
    },
    org_bg_image_upload(file) {
      console.log(file);
      var that = this;
      that.org_bg_image_loading = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_bg_image = location;
            that.org_bg_image_loading = false;
          }
        }
      );
    },
    org_banner_upload0(file) {
      console.log(file);
      var that = this;
      that.org_banner_loading0 = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_banner[0].banner_image = location;
            that.org_banner_loading0 = false;
            that.$forceUpdate();
          }
        }
      );
    },
    org_banner_upload1(file) {
      console.log(file);
      var that = this;
      that.org_banner_loading1 = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_banner[1].banner_image = location;
            that.org_banner_loading1 = false;
            that.$forceUpdate();
          }
        }
      );
    },
    org_banner_upload2(file) {
      console.log(file);
      var that = this;
      that.org_banner_loading2 = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_banner[2].banner_image = location;
            that.org_banner_loading2 = false;
            that.$forceUpdate();
          }
        }
      );
    },
    org_banner_upload3(file) {
      console.log(file);
      var that = this;
      that.org_banner_loading3 = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_banner[3].banner_image = location;
            that.org_banner_loading3 = false;
            that.$forceUpdate();
          }
        }
      );
    },
    org_banner_upload4(file) {
      console.log(file);
      var that = this;
      that.org_banner_loading4 = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString(),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.postForm.org_banner[4].banner_image = location;
            that.org_banner_loading4 = false;
            that.$forceUpdate();
          }
        }
      );
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    redraw() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less">
.el-table .cell {
  padding-right: 0;
}
.el-select .el-input {
  width: 200px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>