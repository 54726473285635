<template>
  <div>
    <h1>文章发表</h1>
    <el-form
      label-width="80px"
      :model="postForm"
      label-position="left"
      :rules="postFormRules"
      ref="postFormRef"
    >
      <el-form-item label="组织名称" prop="user_serial">
        <el-input
          v-model="postForm.user_serial"
          placeholder="该项不可修改"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="文章链接" prop="article_link">
        <el-input
          v-model="postForm.article_link"
          placeholder="https://mp.weixin.qq.com开头的链接，公众号须已关联「薄扶林噗噗」小程序"
        ></el-input>
      </el-form-item>
      <el-form-item label="开启评论">
        <el-switch v-model="postForm.open_comment"></el-switch>
      </el-form-item>
      <el-form-item label="正文内容" v-if="postForm.open_comment">
        <el-input type="textarea" v-model="postForm.post_msg"></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" style="margin-top: 10px" @click="post"
      >发表</el-button
    >
  </div>
</template>

<script>
export default {
  created() {
    this.getUserInfo();
  },
  data() {
    return {
      postForm: {
        user_serial: "",
        article_link: "",
        article_title: "",
        article_image: "",
        open_comment: true,
        post_msg: "",
      },
      postFormRules: {
        user_serial: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        article_link: [
          { required: true, message: "请输入文章链接", trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    async getUserInfo() {
      const token = window.sessionStorage.getItem("token");
      if (!token) {
        this.$message.error("请先登录");
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const { data: res } = await this.$http.post(
        "user/info.php",
        "token=" + token
      );
      console.log(res);
      this.loading = false;
      if (res.code == 200) {
        this.postForm.user_serial = res.user_serial;
      } else {
        window.sessionStorage.removeItem("token");
        this.$message.error("请先登录");
        this.$router.push("/login");
      }
    },
    post() {
      this.$refs.postFormRef.validate(async (valid) => {
        if (!valid) return;
        const token = window.sessionStorage.getItem("token");
        if (!token) {
          this.$message.error("请先登录");
          this.$router.push("/login");
          return;
        }

        const { data: res } = await this.$http.post(
          "post/post.php",
          "token=" +
            token +
            "&article_link=" +
            encodeURIComponent(this.postForm.article_link) +
            "&open_comment=" +
            this.postForm.open_comment +
            "&post_msg=" +
            this.postForm.post_msg
        );
        console.log(res);
        if (res.code == 0) {
          this.$message.success("发表成功");
        } else if (res.code == 5) {
          this.$message.error(res.msg);
        } else {
          window.sessionStorage.removeItem("token");
          this.$message.error("请先登录");
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>

<style lang="less">
.el-table .cell {
  padding-right: 0;
}
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>