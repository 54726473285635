import Vue from 'vue'
import Router from 'vue-router'
import login from '../components/login.vue'
import home from '../components/home.vue'
import post from '../components/post.vue'
import page from '../components/page.vue'
import push from '../components/push.vue'


Vue.use(Router)

const router = new Router({

  routes : [
    { path:'/', redirect: '/home' },
    { path:'/login', component: login },
    { path:'/home',
      component: home,
      redirect: '/post',
      children: [
        { path:'/post', component: post },
        { path:'/push', component: push },
        { path:'/page', component: page },
      ]
    },
  ]
  
})

router.beforeEach( (to, from, next) => {
  if(to.path === '/login') return next()
  const token = window.sessionStorage.getItem('token')
  if(!token) return next('/login')
  next()
} )

export default router
